<template>
	<div class="links-layout">
		<div class="header-container">
			<div class="header__back-buttons" @click="goBack">
				<span class="iconify" 
					data-icon="material-symbols:arrow-left-alt-rounded" 
					data-width="21" 
					data-height="21">
				</span>
			</div>
			<div class="header__title" v-if="currentLink">
				{{currentLink.title}}
			</div>
			<div class="header__title" v-else>
				Document
			</div>
		</div>
		<div class="links-layout__content">
			<VueMarkdown 
			v-if="this.isDefinedDoc && this.text"
			:source="text"/>
			<div v-else-if="!this.isDefinedDoc">
				Not found
			</div>
		</div>
		<UsefulLinks/>
	</div>
</template>

<script>
import VueMarkdown from 'vue-markdown-v2';
import UsefulLinks from '../components/UsefulLinks.vue';
import CMS from '../service/cms/service';



export default {
	name: 'LinksLayout',
	components: {
		UsefulLinks,
		VueMarkdown,
	},
	computed: {
		currentLink(){
			const docName = this.$route.params.docName
			const link = this.links.find(l => {
				return l.name == docName
			})
			return link
		},
	},
	watch: {
		'$route.params.docName' : {
			handler(newDocName){
				this.isDefinedDoc = !!this.links.find(link => {
					return newDocName == link.name
				})
				if (!this.isDefinedDoc)
					return 
				this.setupText(newDocName)
			},
		}
	},
	data() {
		return {
			links: [
				{title: "Terms", name: "terms"},
				{title: "Privacy", name: "privacy-policy"},
				{title: "Help"},
				{title: "Company"},
			],
			text: null,
			isDefinedDoc: false,
		};
	},

	created(){
		const newDocName = this.$route.params.docName
		this.isDefinedDoc = !!this.links.find(link => {
			return newDocName == link.name
		})
		if (!this.isDefinedDoc)
			return 
		this.setupText(this.$route.params.docName)
	},

	methods: {
		goBack(){
			const originHome = JSON.parse(localStorage.getItem('originPage-home'))
			localStorage.removeItem('originPage-home')
			const originAuth = JSON.parse(localStorage.getItem('originPage-auth'))
			localStorage.removeItem('originPage-auth')

			if (originHome) {
				this.$router.replace(originHome)
				return
			} 
			if (originAuth) {
				this.$router.replace(originAuth)
				return
			}

			this.$router.replace({name: 'home'})
		},
		setupText(name){
			CMS.docs.get(name).then(data => {
				if (data && data.err)
					throw data.err

				this.text = data
			})
		}
	}
};
</script>

<style scoped>

.header-container{
	height: 60px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 16px;
	border-bottom: 1px solid #e6e6e6;
	padding: 16px;
}

.links-layout{
	height: 100dvh;
	position: relative;
	width: 100%;
	overflow: hidden;
}

.links-layout__content{
	height: calc(100dvh - 60px - 50px);
	padding: 16px;
	overflow-y: auto;
}

.header__back-buttons{
	display: flex;
	align-items: center;
	height: 28px;
}

.header__title{
	font-size: 24px;
	font-weight: 600;
}
</style>